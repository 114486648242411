<template>
  <div>
    <div id="image_wrapper">
      <img :src="require('@/assets/bg.jpg')" />
    </div>
    <v-overlay :value="overlay" :opacity="0.5" class="login_overlay">
      <v-card rounded width="850px">
        <v-card-title>
          <v-btn outlined @click="$router.push('/login')">
            <v-icon left>mdi-arrow-left</v-icon>
            Back
          </v-btn>
          <v-spacer></v-spacer>
          <span>Reset Password</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-tabs v-model="tab" background-color="transparent" grow>
            <!-- Tab Headers -->
            <!--
            <v-tab disabled>
              Reset with email
            </v-tab>
            -->
            <v-tab> Reset with existing user credentials </v-tab>

            <v-tab> Forgot Password? </v-tab>

            <!-- Tabs -->
            <v-tabs-items v-model="tab" style="background-color: transparent">
              <!--
              <v-tab-item disabled>
                <v-card
                  flat
                  class="tab_card"
                >
                  <v-card-text style="justify-content: center;">
                    <v-row class="mt-4">
                      <v-col cols="12">
                        <v-text-field
                          outlined
                          label="Email"
                          v-model="email"
                          hide-details="auto"
                          dark
                          :rules="[requiredValidation, emailValidation]"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions style="justify-content: center;">
                    <v-btn
                      color="#b31616"
                      outlined
                      width="20%"
                      style="background-color: rgba(179, 22, 22, 0.05)"
                      @click="resetWithEmail"
                    >
                      Reset
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-tab-item>
              -->

              <v-tab-item>
                <v-card flat class="tab_card">
                  <v-card-text style="justify-content: center">
                    <v-row class="mt-4">
                      <!-- Username -->
                      <v-col cols="12">
                        <v-text-field
                          tabindex="1"
                          outlined
                          label="Email"
                          v-model="email"
                          hide-details="auto"
                          dark
                          :rules="[requiredValidation, emailValidation]"
                        ></v-text-field>
                      </v-col>

                      <!-- Old Password -->
                      <v-col cols="12">
                        <v-text-field
                          tabindex="1"
                          outlined
                          label="Old Password"
                          v-model="oldPassword"
                          hide-details="auto"
                          dark
                          :rules="[requiredValidation]"
                          :type="oldPasswordShown ? 'text' : 'password'"
                        >
                          <template v-slot:append>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-on="on"
                                  v-bind="attrs"
                                  style="cursor: pointer"
                                  @click="oldPasswordShown = !oldPasswordShown"
                                  >{{
                                    oldPasswordShown ? "mdi-eye-off" : "mdi-eye"
                                  }}</v-icon
                                >
                              </template>
                              <span>{{
                                oldPasswordShown ? "Hide" : "Show"
                              }}</span>
                            </v-tooltip>
                          </template>
                        </v-text-field>
                      </v-col>

                      <!-- New Password -->
                      <v-col cols="12">
                        <v-text-field
                          tabindex="2"
                          outlined
                          label="New Password"
                          v-model="newPassword"
                          hide-details="auto"
                          dark
                          :rules="[requiredValidation]"
                          :type="newPasswordShown ? 'text' : 'password'"
                          :hint="passwordComplexityText"
                          loading
                        >
                          <template v-slot:append>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-on="on"
                                  v-bind="attrs"
                                  style="cursor: pointer"
                                  class="mr-2"
                                  @click="randomizePassword"
                                >
                                  mdi-dice-6
                                </v-icon>
                              </template>
                              <span>Randomize</span>
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-on="on"
                                  v-bind="attrs"
                                  style="cursor: pointer"
                                  @click="newPasswordShown = !newPasswordShown"
                                  >{{
                                    newPasswordShown ? "mdi-eye-off" : "mdi-eye"
                                  }}</v-icon
                                >
                              </template>
                              <span>{{
                                newPasswordShown ? "Hide" : "Show"
                              }}</span>
                            </v-tooltip>
                          </template>
                          <template v-slot:progress>
                            <v-progress-linear
                              v-if="newPassword"
                              :value="passwordComplexity"
                              :color="passwordComplexityColor"
                              absolute
                              height="7"
                            ></v-progress-linear>
                          </template>
                        </v-text-field>
                      </v-col>

                      <!-- New Password Confirmation -->
                      <v-col cols="12">
                        <v-text-field
                          tabindex="3"
                          outlined
                          label="Confirm New Password"
                          v-model="newPasswordConfirmation"
                          hide-details="auto"
                          dark
                          :rules="[requiredValidation, matchValidation]"
                          :type="
                            newPasswordConfirmationShown ? 'text' : 'password'
                          "
                        >
                          <template v-slot:append>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-on="on"
                                  v-bind="attrs"
                                  style="cursor: pointer"
                                  @click="
                                    newPasswordConfirmationShown =
                                      !newPasswordConfirmationShown
                                  "
                                  >{{
                                    newPasswordConfirmationShown
                                      ? "mdi-eye-off"
                                      : "mdi-eye"
                                  }}</v-icon
                                >
                              </template>
                              <span>{{
                                newPasswordConfirmationShown ? "Hide" : "Show"
                              }}</span>
                            </v-tooltip>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions style="justify-content: center">
                    <v-btn
                      tabindex="4"
                      color="#b31616"
                      outlined
                      width="20%"
                      style="background-color: rgba(179, 22, 22, 0.05)"
                      @click="resetWithPassword"
                    >
                      Reset
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat class="tab_card" min-height="200px">
                  <v-card-text>
                    <span :style="'color: #ffffff'" class="mt-4"
                      >Contact partner management to reset your password in case
                      you completely forgot it</span
                    >
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-card-text>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Reset",
  data: () => ({
    overlay: true,
    tab: null,
    email: "",

    email: "",
    oldPassword: "",
    newPassword: "",
    newPasswordConfirmation: "",

    oldPasswordShown: false,
    newPasswordShown: false,
    newPasswordConfirmationShown: false,

    snackbar: false,
    snackbarMessage: "",

    tmp: {
      message: "Request failed with status code 401",
      name: "Error",
      stack:
        "Error: Request failed with status code 401\n    at createError (webpack-internal:///./node_modules/axios/lib/core/createError.js:16:15)\n    at settle (webpack-internal:///./node_modules/axios/lib/core/settle.js:17:12)\n    at XMLHttpRequest.onloadend (webpack-internal:///./node_modules/axios/lib/adapters/xhr.js:66:7)",
      config: {
        transitional: {
          silentJSONParsing: true,
          forcedJSONParsing: true,
          clarifyTimeoutError: false,
        },
        transformRequest: [null],
        transformResponse: [null],
        timeout: 0,
        xsrfCookieName: "XSRF-TOKEN",
        xsrfHeaderName: "X-XSRF-TOKEN",
        maxContentLength: -1,
        maxBodyLength: -1,
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          token:
            "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwibmFtZSI6ImFkbWluIiwiZW1haWwiOiJhZG1pbiIsInVzZXJfcGFzcyI6IiQyYiQwOCRNbVN6bTdONlBVdEx4ODFhbnpRSk4uMC50MG9HWklQMGhIZk5HMG5zSE9hQm9oMGg1TTA3dSIsImlzX2FkbWluIjp0cnVlLCJpc19tYW5hZ2VyIjpmYWxzZSwiaXNfcGFydG5lciI6ZmFsc2UsImlzX2N1c3RvbWVyIjpmYWxzZSwiY3VzdG9tZXJpZCI6bnVsbCwiY29udGFjdGlkIjpudWxsLCJpYXQiOjE2NTU3OTc3NDIsImV4cCI6MTY1NTg0MDk0MiwiYXVkIjoiQ09CSSBMaWNlbnNlIFBvcnRhbCBVc2VyIiwiaXNzIjoiQ09NUC5uZXQgR21iSCJ9.Mdj9yoCNJiHDeWmWi09_baRzh6LEMeJ7HAYt4AuOvC0-fqbVZpFYxrq0W5Ubw9XOPsB9N716yR_4ScUSZpTSP8PDnMpMYW5J71Xm5zUUfHJju-6rrE6dSPyEQtA-urdqLR23qpGq4NwR5UsI19uBn927QHRigfBS5z--55iWxks",
        },
        baseURL: "http://localhost:3128",
        method: "patch",
        url: "/passwordWithUsername",
        data: '{"email":"admin","password":"Comp.admin2020@net_","newPassword":"Comp.admin2020@net_"}',
      },
      status: 401,
    },
  }),
  computed: {
    ...mapGetters({
      newPasswordResetWithUsername: "newPasswordResetWithUsername",
    }),
    passwordComplexity: function () {
      var maxComplexity = 4;
      var complexityValue = 100 / maxComplexity;
      var complexity = complexityValue;
      if (this.newPassword.length >= 10) complexity += complexityValue;
      if (/\d/.test(this.newPassword)) complexity += complexityValue;
      if (/[A-Z]/.test(this.newPassword)) complexity += complexityValue;
      return Math.ceil(complexity);
    },
    passwordComplexityColor: function () {
      var passwordComplexity = this.passwordComplexity;

      if (passwordComplexity >= 100) return "#36d94f";
      else if (passwordComplexity >= 75) return "#eded47";
      else if (passwordComplexity >= 50) return "#fa9d23";
      else if (passwordComplexity >= 25) return "#b31616";
    },
    passwordComplexityText: function () {
      if (this.newPassword.length < 10) return "At least 10 characters";
      if (!/\d/.test(this.newPassword)) return "At least one Number";
      if (!/[A-Z]/.test(this.newPassword))
        return "At least one upper case character";
    },
  },
  methods: {
    ...mapActions({
      showSnackbar: "showSnackbar",
      patchPasswordWithUsername: "patchPasswordWithUsername",
    }),
    randomizePassword: function () {
      const pw = require("../../helpers/passwordgenerator/passwordgenerator");
      var password = pw.getPassword();
      this.newPassword = password;
      this.newPasswordConfirmation = password;
      console.log(password);
    },

    resetWithPassword: function () {
      console.log("password...");
      if (this.newPassword != this.newPasswordConfirmation)
        return this.showSnackbar("Confirmation does not match ");
      if (!this.email || this.email === "")
        return this.showSnackbar("Username is required");

      var pwObj = JSON.parse(JSON.stringify(this.newPasswordResetWithUsername));
      pwObj.email = this.email;
      pwObj.password = this.oldPassword;
      pwObj.newPassword = this.newPassword;
      console.log(pwObj);

      this.patchPasswordWithUsername(pwObj)
        .then(() => {
          this.showSnackbar("Password has been reset");
          this.$router.push("/login");
        })
        .catch((exception) => {
          this.showSnackbar("Password could not be reset");
        });
      // this.patchPasswordWithUsername(pwObj).then((error) => {
      //   if (error) {
      //     console.log("error:");
      //     console.log(error);
      //     this.showSnackbar("Password could not be reset. Error: " + error);
      //   } else {
      //     this.showSnackbar("Password has been reset");
      //     this.$router.push("/login");
      //   }
      // });
    },
    resetWithEmail: function () {
      console.log("email...");
    },

    emailValidation: function (value) {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid Email";
      
    },
    matchValidation: function (value) {
      return this.newPassword == value || "Does not match";
    },
    requiredValidation: function (value) {
      return !!value || "Required";
    },
  },
  async mounted() {
    this.tmpNewPasswordReset = JSON.parse(
      JSON.stringify(this.newPasswordReset)
    );
  },
};
</script>

<style scoped>
#image_wrapper,
img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.login_overlay {
  backdrop-filter: blur(40px);
}
.tab_card {
  background-color: transparent;
}
</style>







